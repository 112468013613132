
.card{
    display: flex;
    justify-content: space-between;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 10px 20px;
}
.leftContainer{
    display: flex;
    gap: 30px;
    align-items: center;
}

.questionDetails{
    display: flex;
   gap: 30px;
}

.imgtext{
    display: flex;
    align-items: center;
    gap: 20px;
}
.title{
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
}
.text{
    color: rgba(0, 0, 0, 0.5);
}

.text > h4{
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}
.text > p{
    font-size: 16px;
    font-weight: 400;
    margin:  0;
}

.teketestbtn{
    background-color: transparent;
    color: #032C6B;
    border: 1px solid #032C6B;
    border-radius: 10px;
    align-self: center;
    width: 10%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;
}
.CourseHeading{
    color: #032C6B;
    font-size: 28px;
    font-weight: 700;
    text-transform: capitalize;
}
@media only screen and (max-width: 1080px) and (min-width: 320px) {
    .card{
        flex-direction: column;
        padding: 10px;
    }
    .leftContainer{
        display: flex;
        gap: 30px;
        align-items: center;
        width: 100%;
    }
    .imageContainer{
        width: 103px;
    }
    .questionDetails{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
    }
    .imgtext{
        justify-content: space-around;
    }
    .text{
        display: flex;
        width: 170px;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }
    .infoimg{
        width: 30px;
    }
    .infoimg > img{
        width: 100%;
    }
    .teketestbtn{
        width: 50%;
        margin-top: 20px;
    }
}