.queReviewBox {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  margin-bottom: 20px;
}
.queBar {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.queBar > p {
  /* overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; */
}
.timeingForQ {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
}
.difficultyBarMarks {
  width: 10%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.marksBox {
  color: #143f6b;
  font-size: 14px;
  font-weight: 600;
  background-color: #fff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  padding: 0px 15px;
}
.marksBox > h2 {
  margin: 0;
}
.correctAns {
  background-color: #69d96d;
  color: #fff;
}
.incorrectAns {
  background-color: #ff223e;
  color: #fff;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .queReviewBox {
    display: block;
    padding: 10px;
  }
  .queBar {
    width: 100%;
  }
  .difficultyBarMarks{
    width: 100%;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 20px;
  }

}
