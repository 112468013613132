.drop_profile {
  /* background-color: #ee9435 !important; */
  /* border-radius: 13px !important; */
  padding: 9px 15px !important;
  color: white !important;
  font-weight: 600 !important;
  background: linear-gradient(
    97.14deg,
    #11888a -3.11%,
    #2a5e95 100%
  ) !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  align-items: flex-start !important;
}

.drop_profile:hover {
  background-color: #2a5e95;
}

@media screen and (max-width: 992px) and (min-width: 320px) {
  .drop_profile {
    border-radius: 6px !important;
    margin: 2px !important;
    box-sizing: border-box !important;
    align-items: flex-start !important;
  }
}
