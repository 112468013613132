.mainBox{
    text-align: center;
    width: 160px;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .mainBox{
        text-align: center;
        width: 140px;
        margin: 20px 10px;

        & img{
            width: 100%;
        }
    }
}