.bannerContainer {
  position: relative;
}

.bannerimg {
  width: 100%;

  & img {
    width: 100%;
  }
}
.mobileShow {
  display: none;
}

.bannerText {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);

  & h1 {
    color: #fff;
    font-size: 38px;
    font-weight: 600;

    & span {
      font-size: 30px;
      font-weight: 400;
    }
  }
}

.backarrowClick {
  position: absolute;
  top: 10%;
  left: 5%;
}

/* common styling class */
.sectionHeading {
  font-size: 32px;
  color: #032c6b;
  font-weight: 600;
  margin-bottom: 40px;
}
.pl10 {
  padding-left: 20px;
}

.textcenter {
  text-align: center;
}

/* what we do  section styling*/

.whatwedoSection {
  padding: 50px;
}
.whatwedoCardListing {
  display: flex;
  justify-content: space-between;
  /* margin-top: 50px; */
}

/* video section */
.videoSection{
  padding: 50px;
}

.videoContainer{
  width: 100%;
  margin: 50px auto;
  border-radius: 25px;
  overflow: hidden;
  min-height: 500px;
}


.innerVideoDisplay{
  width: 100% !important;
  min-height: 500px;
}


/* courses section */

.coursesSection {
  background-color: #d8fffd;
  padding: 25px 50px;
}

.cardlisting {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

/* tools section */

.toolsSection {
  padding: 50px 100px;
}

.border {
  box-sizing: border-box;
  border: 1px solid #000;
  padding: 30px 60px;
  border-radius: 20px;
}

/* apply now container */
.applynowSection{
  background-color: #7AB2BD;
  padding: 30px 60px;
  margin: 40px 0;
}

.applynowtext{
  display: flex;
  justify-content: space-around;
  align-items: center;

  & p{
    font-size: 26px;
    font-weight: 500;
    color: #fff;
    width: 47%;
  }
}
.applynowbtn{
  background-color: #11898A !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 600 !important; 
  box-shadow: 5px 5px 0px 2px rgba(0, 0, 0, 0.50) !important;
}

/* why us */
.whyus{
  background-color: #F9F9F9;
  padding: 30px 40px;
}

.highlighttext{
  font-size: 26px;
  font-weight: 600;
  color: #11898A;
}

.normalpara{
  font-size: 18px;
  font-weight: 300;
  color: #000;
  width: 60%;
  margin: 0 auto;
  line-height: 1.4;
}

.heghlightpara{
  font-size: 18px;
  font-weight: 400;
  color: #11898A;
  width: 45%;
  margin: 50px auto;
}
/* outcom section */
.outcomeSection{
  padding: 30px;
}

.outcomeimgContainer{
  width: 50%;
  margin: 0 auto;
  & img{
    width: 100%;
  }
}

/* buy section */
.buyOurkitsection{
  padding: 30px 50px;
}

.buycardListing{
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.LastText{
  margin-top: 50px;
}
.buynowLastText{
  font-size: 18px;
  font-weight: 500;
}

.coonectbtn{
  background-color: #13878B !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  /* common styling class */
  .sectionHeading {
    font-size: 22px;
   
  }
  .pl10 {
    padding-left: 0px;
  }
  .pcShow {
    display: none;
  }

  .mobileShow {
    display: inline-block;
    & img {
      width: 100%;
    }
  }
  .bannerText {
    top: 50%;
    left: 45%;
    width: 75%;

    & h1 {
      color: #fff;
      font-size: 20px;
      font-weight: 600;

      & span {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .backarrowClick {
    width: 17px;
    top: 10%;
    left: 5%;

    & img {
      width: 100%;
    }
  }
  .videoContainer{
    
    min-height: 300px;
  }
  .innerVideoDisplay{
    width: 100% !important;
    min-height: 300px !important;
  }

  /* what we do  section styling*/
  .whatwedoSection {
    padding: 20px;
  }

  .whatwedoCardListing {
    display: flex;
    /* gap: 10px; */
    /* margin-top: 50px; */
    overflow: scroll;
  }
  /* courses section */

  .coursesSection {
    background-color: #d8fffd;
    padding: 25px 30px;
  }

  .cardlisting {
    display: flex;
    overflow: scroll;
    gap: 20px;
  }
  /* tools section */

  .toolsSection {
    padding: 20px;
  }

  .border {
    box-sizing: border-box;
    border: 1px solid #000;
    padding: 30px 20px;
    border-radius: 20px;
  }


/* apply now container */
.applynowSection{
  background-color: #7AB2BD;
  padding: 30px ;
  margin: 40px 0;
}

.applynowtext{
 display: block;

  & p{
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    width: 100%;
    margin-bottom: 20px;
  }
}
.applynowbtn{
  background-color: #11898A !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 600 !important; 
  box-shadow: 2px 2px 0px 2px rgba(0, 0, 0, 0.50) !important;
}

/* why us */
.whyus{
  background-color: #F9F9F9;
  padding: 30px 10px;
}

.highlighttext{
  font-size: 20px;
  font-weight: 600;
}

.normalpara{
  font-size: 14px;
  font-weight: 300;
  color: #000;
  width: 100%;
  margin: 0 auto;
  line-height: 1.4;
}

.heghlightpara{
  font-size: 16px;
  font-weight: 400;
  color: #11898A;
  width: 100%;
  margin: 50px auto;
}
.outcomeSection{
  padding: 20px;
}
.outcomeimgContainer{

  width: 100%;
  /* margin: 0 auto; */
  & img{
    width: 100%;
  }
}

/* buy section */
.buyOurkitsection{
  padding: 20px ;
}

.buycardListing{
  gap: 20px;
  overflow: scroll;
}
/* video section */
.videoSection{
  padding: 10px;
}
.videoContainer{
  width: 100%;
  margin: 30px auto;
  border-radius: 25px;
  overflow: hidden;
}
}
