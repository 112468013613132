@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap");
* {
  /* font-family: Montserrat; */
}

/* #otpless-floating-button{
} */


.MuiDialog-paperWidthSm {
  max-width: max-content !important;
  min-width: 600px !important;
}


.font-blue{
  color: blue;
  font-size: 18px;
}