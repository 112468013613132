.heading{
    font-size: 24px;
    font-weight: 600;
    color: #032C6B;
    text-transform: capitalize;
}
.CourseHeading{
    color: #032C6B;
    font-size: 28px;
    font-weight: 700;
    text-transform: capitalize;
    display: none;
}
.chapterheading{
    font-size: 18px;
    font-weight: 400;
    color: #000;
    text-transform: capitalize;
    margin-top: 0;
}
.pptsCards{
    display: flex;
    gap: 20px;
}
.slider{
    text-align: center;
}
.pptCard:first-child{
    /* padding-left: 0px !important; */
}
.pptCard{
    position: relative;
    /* display: inline-block; */
    border: none;
    background-color: transparent;
    text-decoration: none;
    width: 100%;
    overflow: hidden;
    /* border-radius: 10px; */
    margin-right:10px;
    padding-left: 0;
    cursor: pointer;
}
/* .pptCard::after{
    position: absolute;
    background: linear-gradient(180deg, rgba(0,0,0,0.1) 50%, rgba(0,0,0,1) 100%);
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    overflow: hidden;
} */

.pptCard > div > img{
    width: 100%;
    border-radius: 10px;
}
.pptCard > div::after{
    position: absolute;
    background: linear-gradient(180deg, rgba(0,0,0,0.2) 50%, rgba(0,0,0,1) 100%);
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}

.pptCardText{
    position: absolute;
    bottom: 0px;
    right: 0px;
    color: #fff;
    /* background: linear-gradient(180deg, rgba(0,0,0,0.1) 50%, rgba(0,0,0,1) 100%);
    background-color: rgba(0, 0, 0, 0.5); */
    /* display: block; */
    border-radius: 10px;
    width: 100%;
    /* height: 100%; */
    z-index: 2;
}

.pptCardText > h5{
    margin: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}

.pptCardText > p{
    margin: 5px 10px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;

}

.react-multi-carousel-dot--active button {
    background: #13868b;
}
.react-multi-carousel-dot button{
    border: none !important;
}

.backarrowText{
    margin-left: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #032C6B;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
    .CourseHeading{
        display: block;
    }
}