.BuycardBox{
    width: 20%;
    background-color: #F9F9F9;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 5px;
}

.imgContainer{
    width: 100%;
    margin-top: -90px;
    & img{
        width: 100%;
    }
}

.kitName{
    font-size: 18px;
    font-weight: 600;
    color: #0B0A0A;
}

.kitdesc{
    font-size: 16px;
    font-weight: 400;
    color: #0B0A0A;
}

.buybtn{
    background: linear-gradient(180deg, rgba(19,135,139,1) 50%, rgba(43,95,150,1) 100%);
    color: #fff !important;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
}


@media only screen and (max-width: 767px) and (min-width: 320px) {
    .BuycardBox{
        width: 250px;
        margin-top: 20px;
    }

    .imgContainer{
        width: 200px;
        margin-top: -60px;
        & img{
            width: 100%;
        }
    }
    
}