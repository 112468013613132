.main_blog{
    margin-top: 70px;
}

.blogs_handle{
    display: flex;
    max-width: 1500px;
    margin: 0 auto;
    padding: 0 20px;
}

.blg_container{
    /* margin-top: 60px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}
.blg_image{
    width: 100%;
    height: 187px;
}
.box_blg{
    width: 30%;
    margin: 10px;
    overflow: hidden;
    cursor: pointer;
    background-color: #f2f2f2;
    box-shadow:3px 5px 6px -1px rgb(0 0 0 / 12%);
    border-radius: 10px;
    display: inline-flex;
    flex-direction: column;
    white-space: normal;
    position: relative;
    padding: 10px;
}

.box_blg_skeleton{
    width: 25%;
    margin: 10px;
    border-radius: 12px;
}

.bg_category{
    padding: 3px 9px 3px;
    font-size: 16px;
    color: #303a7d;
    font-weight: 600;
}

.blg_content_main{
    padding: 0 10px;
}

.blg_content_main img{
    width:100%;
    height: auto;
}

.contents_main{
    width: 100%;
}
.blogContent{
    font-size: 19px;
    color: #2c2c2c;
    font-weight: 600;
    line-height: 1.2;
    text-transform: capitalize;
    margin-top: 10px;
}

.blog_Content{
    font-size: 19px;
    color: #2c2c2c;
    font-weight: 600;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-transform: capitalize;
}
.displayed{
    max-width: 1300px;
    margin: 0 auto;
    padding: 10px 30px;
    text-align: justify;
}

.sel_image{
    width: 100%;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .box_blg{
        width: 45%;
    }
}
@media screen and (max-width: 768px) and (min-width: 320px) {
    .blg_container{
        flex-direction: column;
        margin: 0;
        margin-top: 65px;
    }

    .box_blg{
        width: 300px;
        margin: 0 auto 20px;
    }

    .displayed{
        margin: 65px 0 0;
    padding: 0;
    }
    .blg_container_main div p img{
        width: 100% !important;
    }
    .contents_main{
        width: 100%;
    }
}