.mainContainer{
    width: 95%;
    margin: 0 auto;
    min-height: 65vh;
}
.QuestionBox{
    width: 80%;
    margin:  auto;
}

.question{
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);
    padding: 10px;
    border-radius:10px ;
    position: relative;

    & h5{
        font-size: 20px;
        font-weight: 500;
    }

    & p{
        font-size: 20px;
        font-weight: 400;
    }
}

.BtnBox{
    margin: 20px 0;
}

.btnanswer{
    color: #fff;
    background-color: #3F51B5FC;
    border-radius: 5px;
    text-transform: uppercase;
    border: none;
    width: 30%;
    padding: 10px 0;
    font-size: 20px;
    font-weight: 700;
}
.QuestionMarks{
    position: absolute;
    top: 20px;
    right: 0;
    background-color: #032C6B;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px 0 0 5px;
    font-size: 18px;
    font-weight: 400;
}

.closebtn{
    text-align: right;
}
.resultModel{
    & h3{
        text-transform: capitalize;
        color:#032C6B;
        font-size: 22px;
        font-weight: 500;
    }

    & p{
        color: rgba(0, 0, 0, 0.50);
    }
}
.uploadBtn{
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    width: 100%;
    background: linear-gradient(90deg, rgba(19,134,139,1) 30%, rgba(3,44,107,1) 100%);
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
}

input[type="file"]{
    display: none;
  }

  .inputfile{
    cursor: pointer;
    width: 100%;
    border: 1px dashed #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    padding: 10px;
    box-sizing: border-box;
    gap: 15px;
  }
  .ufImgContainer{
    display: flex;
    gap: 20px;
    align-items: center;
    color: rgba(0, 0, 0, 0.50);
  }
  .fileFormat{
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }
  .downloadepaper{
    color: #fff;
    background-color: #3F51B5FC;
    width: 20%;
    text-align: center;
    margin-right: 0;
    margin-left: auto;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    display: flex;
    font-size: 16px;
    justify-content: center;
    align-items: center;
  }
@media only screen and (max-width: 992px) and (min-width: 320px) {

    .mobileS{
        width: 280px !important;
        padding: 20px;
    }
    .QuestionBox{
        width: 95%;
        margin:  auto;
    }
    .question{
    
        & h5{
            font-size: 16px;
            font-weight: 500;
        }
    
        & p{
            font-size: 16px;
            font-weight: 400;
        }
    }

    .BtnBox{
        display: flex;
        gap: 10px;
        margin: 30px 0;
    }
    .btnanswer{
        width: 47%;
        font-weight: 500;
        font-size: 16px;
        margin: 0 !important;
    }
}